<template>
  <div>
    <div class="acc_border acc_top flex_center_between_box">
      <div class="flex_center_start_box">
        <label class="acc_top_lbl">账户信息</label>
        <div class="progress">
          <div class="progress_bar" :style="'width:'+accountObj.completionRate+'%;background:#339C9B;'"><span></span></div>
        </div>
        <span class="acc_top_pro">信息完整度{{accountObj.completionRate?accountObj.completionRate:0}}%</span>
      </div>
      <div class="button m_btn_left1" @click="editAccount()">编辑信息</div>
      <div class="button m_btn_left2" @click="sureServicesDialog=true">认证服务</div>
    </div>
    <div class="flex_center_between_box">
      <div class="acc_border acc_cont_box">
        <div class="acc_item flex_center_start_box">
          <div class="acc_lbl">账户编号：</div>
          <div class="acc_inpt">
            <span v-if="accountObj.roleId=='1'">{{accountObj.supplyRoleId}}</span>
            <span v-if="accountObj.roleId=='3'">{{accountObj.enterRoleId}}</span>
            <span v-if="accountObj.roleId=='2'">{{accountObj.manageRoleId}}</span>
          </div>
          <div class="acc_ic">
            <el-tooltip class="item_success" effect="light" content="已认证" placement="top" v-if="accountObj.roleId">
              <i class="iconfont icon-yirenzheng"></i>
            </el-tooltip>
            <el-tooltip class="item_fail" effect="light" content="认证失败" placement="top" v-else>
              <i class="iconfont icon-shibai-fail"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="acc_item flex_center_start_box">
          <div class="acc_lbl">公司名称（中文）: </div>
          <div class="acc_inpt one_txt">
            <el-tooltip  effect="light" :content="accountObj.cnCompany" placement="top-start" >
              <div>{{accountObj.cnCompany}}</div>
            </el-tooltip>
          </div>
          <div class="acc_ic">
            <el-tooltip class="item_success" effect="light" content="已认证" placement="top" v-if="accountObj.cnCompany">
              <i class="iconfont icon-yirenzheng"></i>
            </el-tooltip>
            <el-tooltip class="item_fail" effect="light" content="认证失败" placement="top" v-else>
              <i class="iconfont icon-shibai-fail"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="acc_item flex_center_start_box">
          <div class="acc_lbl">公司名称（英文）: </div>
          <div class="acc_inpt one_txt">
            <el-tooltip  effect="light" :content="accountObj.enCompany" placement="top-start" >
              <div>{{accountObj.enCompany}}</div>
            </el-tooltip>
          </div>
          <div class="acc_ic">
            <el-tooltip class="item_success" effect="light" content="已认证" placement="top" v-if="accountObj.enCompany">
              <i class="iconfont icon-yirenzheng"></i>
            </el-tooltip>
            <el-tooltip class="item_fail" effect="light" content="认证失败" placement="top" v-else>
              <i class="iconfont icon-shibai-fail"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="acc_item flex_center_start_box">
          <div class="acc_lbl">统一社会信用代码：</div>
          <div class="acc_inpt">{{accountObj.creditCore}}</div>
          <div class="acc_ic">
            <el-tooltip class="item_success" effect="light" content="已认证" placement="top" v-if="accountObj.creditCore">
              <i class="iconfont icon-yirenzheng"></i>
            </el-tooltip>
            <el-tooltip class="item_fail" effect="light" content="认证失败" placement="top" v-else>
              <i class="iconfont icon-shibai-fail"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="acc_item flex_center_start_box">
          <div class="acc_lbl">企业类型：</div>
          <div class="acc_inpt">{{accountObj.isLock}}</div>
          <div class="acc_ic">
            <el-tooltip class="item_success" effect="light" content="已认证" placement="top" v-if="accountObj.isLock">
              <i class="iconfont icon-yirenzheng"></i>
            </el-tooltip>
            <el-tooltip class="item_fail" effect="light" content="认证失败" placement="top" v-else>
              <i class="iconfont icon-shibai-fail"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="acc_item flex_center_start_box">
          <div class="acc_lbl">所属行业：</div>
          <div class="acc_inpt">{{accountObj.industry}}</div>
          <div class="acc_ic">
            <el-tooltip class="item_success" effect="light" content="已认证" placement="top" v-if="accountObj.industry">
              <i class="iconfont icon-yirenzheng"></i>
            </el-tooltip>
            <el-tooltip class="item_fail" effect="light" content="认证失败" placement="top" v-else>
              <i class="iconfont icon-shibai-fail"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="acc_item flex_center_start_box">
          <div class="acc_lbl">主要产品：</div>
          <div class="acc_inpt one_txt">
            <el-tooltip  effect="light" :content="accountObj.mainProducts" placement="top-start" >
              <div>{{accountObj.mainProducts}}</div>
            </el-tooltip>
          </div>
          <div class="acc_ic">
            <el-tooltip class="item_success" effect="light" content="已认证" placement="top" v-if="accountObj.mainProducts">
              <i class="iconfont icon-yirenzheng"></i>
            </el-tooltip>
            <el-tooltip class="item_fail" effect="light" content="认证失败" placement="top" v-else>
              <i class="iconfont icon-shibai-fail"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="acc_item flex_center_start_box">
          <div class="acc_lbl">营业执照： </div>
          <div class="acc_inpt"> <img v-if="accountObj.bussLicense" class="pdf_img" :src="jpg + accountObj.bussLicense" /></div>
          <div class="acc_ic">
            <el-tooltip class="item_success" effect="light" content="已认证" placement="top" v-if="accountObj.bussLicense">
              <i class="iconfont icon-yirenzheng"></i>
            </el-tooltip>
            <el-tooltip class="item_fail" effect="light" content="认证失败" placement="top" v-else>
              <i class="iconfont icon-shibai-fail"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="acc_item flex_center_start_box">
          <div class="acc_lbl">银行账号: </div>
          <div class="acc_inpt">{{accountObj.bankAccount}}</div>
          <div class="acc_ic">
            <el-tooltip class="item_success" effect="light" content="已认证" placement="top" v-if="accountObj.bankAccount">
              <i class="iconfont icon-yirenzheng"></i>
            </el-tooltip>
            <el-tooltip class="item_fail" effect="light" content="认证失败" placement="top" v-else>
              <i class="iconfont icon-shibai-fail"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="acc_item flex_center_start_box">
          <div class="acc_lbl">收款银行: </div>
          <div class="acc_inpt">{{accountObj.bankReceiving}}</div>
          <div class="acc_ic">
            <el-tooltip class="item_success" effect="light" content="已认证" placement="top" v-if="accountObj.bankReceiving">
              <i class="iconfont icon-yirenzheng"></i>
            </el-tooltip>
            <el-tooltip class="item_fail" effect="light" content="认证失败" placement="top" v-else>
              <i class="iconfont icon-shibai-fail"></i>
            </el-tooltip>
          </div>
        </div>
      </div>
      <div class="acc_border acc_cont_box">
        <div class="acc_item flex_center_start_box">
          <div class="acc_lbl">国家地区： </div>
          <div class="acc_inpt one_txt">{{accountObj.country}}</div>
          <div class="acc_ic">
            <el-tooltip class="item_success" effect="light" content="已认证" placement="top" v-if="accountObj.address">
              <i class="iconfont icon-yirenzheng"></i>
            </el-tooltip>
            <el-tooltip class="item_fail" effect="light" content="认证失败" placement="top" v-else>
              <i class="iconfont icon-shibai-fail"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="acc_item flex_center_start_box">
          <div class="acc_lbl">所属省市： </div>
          <div class="acc_inpt one_txt">{{accountObj.province}}{{accountObj.city}}</div>
          <div class="acc_ic">
            <el-tooltip class="item_success" effect="light" content="已认证" placement="top" v-if="accountObj.address">
              <i class="iconfont icon-yirenzheng"></i>
            </el-tooltip>
            <el-tooltip class="item_fail" effect="light" content="认证失败" placement="top" v-else>
              <i class="iconfont icon-shibai-fail"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="acc_item flex_center_start_box">
          <div class="acc_lbl">公司地址： </div>
          <div class="acc_inpt one_txt">
            <el-tooltip  effect="light" :content="accountObj.address" placement="top-start" >
              <div>{{accountObj.address}}</div>
            </el-tooltip>
          </div>
          <div class="acc_ic">
            <el-tooltip class="item_success" effect="light" content="已认证" placement="top" v-if="accountObj.address">
              <i class="iconfont icon-yirenzheng"></i>
            </el-tooltip>
            <el-tooltip class="item_fail" effect="light" content="认证失败" placement="top" v-else>
              <i class="iconfont icon-shibai-fail"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="acc_item flex_center_start_box">
          <div class="acc_lbl">公司电话： </div>
          <div class="acc_inpt">{{accountObj.tel}}</div>
          <div class="acc_ic">
            <el-tooltip class="item_success" effect="light" content="已认证" placement="top" v-if="accountObj.tel">
              <i class="iconfont icon-yirenzheng"></i>
            </el-tooltip>
            <el-tooltip class="item_fail" effect="light" content="认证失败" placement="top" v-else>
              <i class="iconfont icon-shibai-fail"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="acc_item flex_center_start_box">
          <div class="acc_lbl">公司邮箱：</div>
          <div class="acc_inpt">{{accountObj.email}}</div>
          <div class="acc_ic">
            <el-tooltip class="item_success" effect="light" content="已认证" placement="top" v-if="accountObj.email">
              <i class="iconfont icon-yirenzheng"></i>
            </el-tooltip>
            <el-tooltip class="item_fail" effect="light" content="认证失败" placement="top" v-else>
              <i class="iconfont icon-shibai-fail"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="acc_item flex_center_start_box">
          <div class="acc_lbl">公司网址： </div>
          <div class="acc_inpt">{{accountObj.network}}</div>
          <div class="acc_ic">
            <el-tooltip class="item_success" effect="light" content="已认证" placement="top" v-if="accountObj.network">
              <i class="iconfont icon-yirenzheng"></i>
            </el-tooltip>
            <el-tooltip class="item_fail" effect="light" content="认证失败" placement="top" v-else>
              <i class="iconfont icon-shibai-fail"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="acc_item flex_center_start_box">
          <div class="acc_lbl">联系人姓名： </div>
          <div class="acc_inpt">{{accountObj.cnName}}</div>
          <div class="acc_ic">
            <el-tooltip class="item_success" effect="light" content="已认证" placement="top" v-if="accountObj.cnName">
              <i class="iconfont icon-yirenzheng"></i>
            </el-tooltip>
            <el-tooltip class="item_fail" effect="light" content="认证失败" placement="top" v-else>
              <i class="iconfont icon-shibai-fail"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="acc_item flex_center_start_box">
          <div class="acc_lbl">联系人部门： </div>
          <div class="acc_inpt">{{accountObj.dept}}</div>
          <div class="acc_ic">
            <el-tooltip class="item_success" effect="light" content="已认证" placement="top" v-if="accountObj.dept">
              <i class="iconfont icon-yirenzheng"></i>
            </el-tooltip>
            <el-tooltip class="item_fail" effect="light" content="认证失败" placement="top" v-else>
              <i class="iconfont icon-shibai-fail"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="acc_item flex_center_start_box">
          <div class="acc_lbl">联系人职位： </div>
          <div class="acc_inpt">{{accountObj.position}}</div>
          <div class="acc_ic">
            <el-tooltip class="item_success" effect="light" content="已认证" placement="top" v-if="accountObj.position">
              <i class="iconfont icon-yirenzheng"></i>
            </el-tooltip>
            <el-tooltip class="item_fail" effect="light" content="认证失败" placement="top" v-else>
              <i class="iconfont icon-shibai-fail"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="acc_item flex_center_start_box">
          <div class="acc_lbl">联系人电话： </div>
          <div class="acc_inpt">{{accountObj.phone}}</div>
          <div class="acc_ic">
            <el-tooltip class="item_success" effect="light" content="已认证" placement="top" v-if="accountObj.phone">
              <i class="iconfont icon-yirenzheng"></i>
            </el-tooltip>
            <el-tooltip class="item_fail" effect="light" content="认证失败" placement="top" v-else>
              <i class="iconfont icon-shibai-fail"></i>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>

    <!-- 编辑账户信息弹框 -->
    <el-dialog title="编辑账户信息" :visible.sync="editAccountDialog" center class="edit change_padding inner_common_dialog" :close-on-click-modal="false">
      <div class="edit_account inner_common">
        <el-form :model="accForm" :rules="editAccountRule" ref="accForm" label-width="165">
          <div class="err_bg" v-show="errFlag"><i class="iconfont icon-jinggao" /> &nbsp;<span>{{errMsg}}!</span></div>
          <el-form-item label="公司名称:">
            <el-input v-model="accForm.cnCompany" disabled></el-input>
          </el-form-item>
          <el-form-item label="公司英文名称:">
            <el-input v-model="accForm.enCompany" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item label="统一社会信息代码:" prop="creditCore">
            <el-input v-model="accForm.creditCore" maxlength="18"></el-input>
          </el-form-item>
          <el-form-item label="营业执照:" class="change_width change_flex">
            <el-upload ref="uploadForm" :http-request="handleUploadFile" :before-remove="handleRemove" action="fakeaction" v-model="accForm.bussLicense" class="upload_demo" list-type="picture"
              accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PBG">
              <div class="upload_box">
                <i class="el-icon-plus"></i>
              </div>
            </el-upload>
            <!-- <el-upload ref="uploadForm" :http-request="handleUploadFile" action="fakeaction" v-model="form.invoiceUrl" class="upload-demo" list-type="picture" accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PBG">
              <div class="upload_box">
                <i class="el-icon-plus"></i>
              </div>
              <span slot="tip" class="el-upload__tip">支持JPG、PNG格式，大小不超过15M</span>

            </el-upload> -->
            <div v-show="accForm.bussLicense">
              <ul>
                <li class="li_img">
                  <img :src="jpg + accForm.bussLicense">
                  <div class="close_icon" @click="handleRemove()">
                    <i class="iconfont icon-yuyinguanbi"></i>
                  </div>
                </li>
              </ul>
            </div>
          </el-form-item>
          <el-form-item label="银行账号:" prop="bankAccount">
            <el-input v-model="accForm.bankAccount" maxlength="24" onkeyup='this.value=this.value.replace(/\D/gi,"")'></el-input>
          </el-form-item>
          <el-form-item label="收款银行:" prop="bankReceiving">
            <el-input v-model="accForm.bankReceiving" maxlength="24"></el-input>
          </el-form-item>
          <el-form-item label="企业类型:" prop="enterpriseType">
            <el-select v-model="accForm.enterpriseType" @change="changeEnterpriseType">
              <el-option v-for="item in cnTypeOptions" :key="item.codeState" :label="item.value" :value="item.codeState"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属行业:" prop="industry">
            <el-cascader :placeholder="industryCascader" :show-all-levels="false" v-model="accForm.industry" :props="defaultParmas" clearable filterable></el-cascader>
          </el-form-item>
          <el-form-item label="主要产品:" prop="mainProducts">
            <el-input v-model="accForm.mainProducts" maxlength="24"></el-input>
          </el-form-item>
          <el-form-item label="国家地区:" prop="country">
            <el-select v-model="accForm.country" @change="changeAddress0">
              <!-- <el-option v-for="item in addressList0" :key="item.codeState" :label="item.value" :value="item.codeState"></el-option> -->
              <el-option v-for="item in  addressList0" :key="item.pid" :label="item.name" :value="item.name" />
            </el-select>
          </el-form-item>
          <el-form-item label="所属省市:" prop="city">
            <el-form-item prop="province" class="two_select">
              <el-select v-model="accForm.province" @change="changeAddress1">
                <el-option v-for="item in  addressList1" :key="item.index" :label="item.name" :value="item.name" />
              </el-select>
            </el-form-item>
            <el-form-item prop="city" class="two_select">
              <el-select v-model="accForm.city" @change="changeAddress2">
                <el-option v-for="item in  addressList2" :key="item.index" :label="item.name" :value="item.name" />
              </el-select>
            </el-form-item>
          </el-form-item>
          <el-form-item label="公司地址:" prop="address">
            <el-input v-model="accForm.address" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item label="公司电话:" prop="tel">
            <el-input v-model="accForm.tel" maxlength="12"></el-input>
          </el-form-item>
          <el-form-item label="公司邮箱:" prop="email">
            <el-input v-model="accForm.email" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item label="公司网址:" prop="network">
            <el-input v-model="accForm.network" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item label="联系人姓名:" prop="cnName">
            <el-input v-model="accForm.cnName" maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="联系人部门:" prop="dept">
            <el-input v-model="accForm.dept" maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="联系人职位:" prop="position">
            <el-input v-model="accForm.position" maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="联系人电话:" prop="phone">
            <el-input v-model="accForm.phone" maxlength="11"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button2" round @click="cancelForm()">取 消</el-button>
        <el-button class="footer_button1" type="primary" @click="submitForm('accForm')">提交信息</el-button>
      </span>
    </el-dialog>
    <!-- 认证服务弹框 -->
    <el-dialog title="认证服务" :visible.sync="sureServicesDialog" center class="dialog_width500" :close-on-click-modal="false">
      <div class="revoke_confirm_box">
        {{accountObj.completionRate==100 || accountObj.completionRate==80 ?"认证服务订单已生成":"请完整填写您的必填信息后，才可继续认证。"}}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button1" type="primary" @click="sureServicesDialog=false" v-if="accountObj.completionRate==100 || accountObj.completionRate==80">关 闭</el-button>
        <el-button class="footer_button1" type="primary" @click="goToMes" v-else>跳转至信息填写</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import { getUserInfo, editUser, uploads, findCodeInfoByKey, getAllCity } from "@/api/api.js";
import { findFirmInfoByKey } from "@/api/investor.js";
import { regions } from "@/utils/regions";
import { mixins1 } from "@/mixins/index";
import formValidation from "@/utils/rule.js";
export default {
  mixins: [mixins1],
  data() {
    const valBankReceiving = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入收款银行'))
      } else if (/^[\u4e00-\u9fa5a-zA-Z0-9]+$/.test(val) == false) {
        return cb(new Error("请输入20位中英文或数字"));
      }
      return cb()
    }
    return {
      accountObj: {
        roleId: '',
        enCompany: '',
        creditCore: '',
        bussLicense: [{ name: 'PDF', url: require("@/assets/img/pdf.png") }],
        address: '',
        tel: '',
        email: '',
        network: '',
        cnName: '',
        dept: '',
        position: '',
        phone: '',
        completionRate: 0,
        cnAddress: '',
        enterpriseType: '',
        city: '',
        province: '',
        bankReceiving: '',
        bankAccount: '',
        mainProducts: '',
        industry: '',

      },//展示
      accForm: {
        roleId: '',
        enCompany: '',
        creditCore: '',
        bussLicense: [{ name: 'PDF', url: require("@/assets/img/pdf.png") }],
        address: '',
        tel: '',
        email: '',
        network: '',
        cnName: '',
        dept: '',
        position: '',
        phone: '',
        completionRate: 0,
        cnAddress: '',
        enterpriseType: '',
        city: '',
        province: '',
        bankReceiving: '',
        bankAccount: '',
        isValid: '-1',
        mainProducts: '',
        industry: '',
      },
      cnTypeOptions: [],
      editAccountRule: {
        country: [{ required: true, message: '请选择国家', trigger: 'change' }],
        // province:[{required: true, message: '请选择省',trigger: 'blur'  }],
        city: [{ required: true, message: '请选择市', trigger: 'change' }],
        enterpriseType: [{ required: true, message: '请选择企业类型', trigger: 'change' }],
        creditCore: [{ required: true, message: '请输入统一社会信息代码', trigger: 'blur' }],
        address: [{ required: true, message: '请输入公司地址', trigger: 'blur' }],
        email: [{ required: true, message: '请输入公司邮箱', trigger: 'blur' }, { validator: formValidation.validateEmail, trigger: "blur" }],
        cnName: [{ required: true, message: '请输入联系人姓名', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入联系人电话', trigger: 'blur' }, { validator: formValidation.validateMobile, trigger: "blur" }],
        bankReceiving: [{ required: true, validator: valBankReceiving, trigger: 'blur' }],
        bankAccount: [{ required: true, message: '请输入银行账号', trigger: 'blur' }],
        industry: [{ required: true, message: '请选择所属行业', trigger: 'change' }],
        mainProducts: [{ required: true, message: '请输入主要产品', trigger: 'blur' }],
        position: [{ required: true, message: '请输入联系人职位', trigger: 'blur' }]
      },
      editAccountDialog: false,
      sureServicesDialog: false,
      // isUploadImg:true,
      userId: '',
      errMsg: '',
      errFlag: false,
      addressList0: [],
      addressList1: [],//regions.map(item => { return { name: item.name, code: item.code, children: item.children } }),
      addressList2: [],
      compressImg: require('@/assets/img/system/supplier/invoice.png'),
      levels: 1,//地区层级
      pid: 1,//地区层级
      defaultParmas: {
        lazy: true,
        lazyLoad: this.lazyLoad,
        emitPath: false,
      },
      industryCascader: '',
    }
  },
  created() {
    let obj = JSON.parse(localStorage.getItem('userInfo'))
    this.userId = obj ? obj.userId : ''
    this.getUserInfo()
    this.centerOptionsList()
    this.getCuntryCity()
  },
  methods: {
    submitForm(accForm) {
      console.log(accForm)
      console.log(accForm)
      this.sureServicesDialog = false
      this.$refs[accForm].validate((valid) => {
        if (valid) {
          this.editAccountDialog = false
          this.editInfo()
        } else {
          return false;
        }
      });
    },

    //取消
    cancelForm() {
      this.$refs.accForm.resetFields()
      this.editAccountDialog = false
      this.sureServicesDialog = false
    },

    //编辑信息弹框
    editAccount() {
      //   this.lazyLoad()
      this.accForm = JSON.parse(JSON.stringify(this.accountObj))
      this.industryCascader = this.accForm.industry
      this.editAccountDialog = true
      this.getCuntryCity().then(() => {
        this.changeAddress0(this.accForm.country).then(() => {
          this.changeAddress1(this.accForm.province)
        })
      })
      this.$refs.accForm.resetFields()
    },
    goToMes() {
      this.accForm = JSON.parse(JSON.stringify(this.accountObj))
      this.industryCascader = this.accForm.industry
      this.editAccountDialog = true
      this.getCuntryCity().then(() => {
        this.changeAddress0(this.accForm.country).then(() => {
          this.changeAddress1(this.accForm.province)
        })
      })
    },
    //上传营业执照
    handleUploadFile(params) {
      console.log(params)
      if (!this.accForm.bussLicense) {
        const file = params.file
        let form = new FormData();
        form.append("files", file);
        uploads(form).then(res => {
          this.accForm.bussLicense = res[0]
        })
      }
    },
    handleRemove() {
      this.accForm.bussLicense = ''
    },
    //换PDF图片
    //   handleChange(file){
    //     this.isUploadImg= !this.isUploadImg
    //     console.log(this.accForm.businessLicense)
    //   },

    //编辑信息
    async editInfo() {
      this.accForm.isValid = '-1'
      let res = await editUser(this.accForm)
      if (res && res.code == 200) {
        this.editAccountDialog = false
        this.sureServicesDialog = false
        this.getUserInfo()
      }
      else {
        this.errFlag = true
        this.errMsg = res.msg
      }
    },
    //userId查询获取基础信息
    async getUserInfo() {
      let res = await getUserInfo('userId=' + this.userId)
      if (res && res.code == 200) {
        this.accountObj = res.data
      }
      else {
        this.$message.error(res.msg)
      }
    },
    //国家
    async getCuntryCity() {
      let res = await getAllCity('levels=' + this.levels + '&pid=' + this.pid)
      if (res && res.code == 200) {
        let list = res.data
        this.addressList0 = list
      }
    },
    //国家
    async changeAddress0(val) {
      if (val) {
        this.$refs.accForm.validateField("country")
        // this.accForm.province=''
        // this.accForm.city=''
        //  this.addressList2 = []
        let sec = {}
        sec = this.addressList0.filter(item => item.name == val)[0]
        let res = await getAllCity('levels=2' + '&pid=' + sec.sid)
        if (res && res.code == 200) {
          let list = res.data
          this.addressList1 = list
          if (list.length < 1) {
            this.accForm.province = ''
            this.accForm.city = ''
            let res2 = await getAllCity('levels=3' + '&pid=' + sec.sid)
            if (res2 && res2.code == 200) {
              let list2 = res2.data
              this.addressList2 = list2
            }
          }
        }
      } else {
        this.addressList1 = []
        this.addressList2 = []
      }
    },
    //省市联动
    async changeAddress1(val) {
      console.log('city', val)
      if (val) {
        this.$refs.accForm.validateField("province")
        // this.accForm.city=''
        let thr = {}
        thr = this.addressList1.filter(item => item.name == val)[0]
        let res = await getAllCity('levels=3' + '&pid=' + thr.sid)
        if (res && res.code == 200) {
          let list = res.data
          this.addressList2 = list
        }
      } else {
        this.addressList2 = []
      }
    },

    //下拉框校验
    changeAddress2(val) {
      if (val) {
        this.$refs.accForm.validateField("city")
      }
    },

    //下拉框校验
    changeEnterpriseType(val) {
      if (val) {
        this.$refs.accForm.validateField("enterpriseType")
      }
    },
    //字段合作年限
    async centerOptionsList() {
      let res = await findCodeInfoByKey('ms=28')
      this.cnTypeOptions = res.data
      //let resC = await findCodeInfoByKey('ms=8')
      //this.addressList0= resC.data
    },
    //所属行业
    async lazyLoad(node, resolve) {
      let level = node.level
      let result
      let res
      let csic
      switch (level) {
        case 0:  //一级目录
          res = await findFirmInfoByKey()
          result = res.data
          result.forEach((item) => {
            item.value = item.firmName
            item.label = item.firmName
            csic = item.csic
          });
          break;
        case 1: // 二级目录
          res = await findFirmInfoByKey('csic=' + node.data.csic)
          result = res.data
          result.forEach((item) => {
            item.value = item.firmName
            item.label = item.firmName
            // 大于二级后不再显示后面的，到此结束
            item.leaf = level >= 1
          });
          break;
        default:
          result = [];
          break;
      }
      resolve(result);
    },
  }
}
</script>
<style scoped>
  ::v-deep .el-cascader .el-input .el-input__inner:hover {
    border: 1px solid #e1e7ea;
  }

  ::v-deep .place_cascader .el-cascader .el-input .el-input__inner::placeholder {
    visibility: hidden;
  }

  ::v-deep .el-cascader .el-input .el-input__inner::placeholder {
    color: #606266;
  }

  ::v-deep .el-cascader .el-input.is-focus .el-input__inner {
    border-color: #e1e7ea;
  }

  ::v-deep .el-cascader:not(.is-disabled):hover .el-input__inner {
    border: 1px solid #e1e7ea;
  }

  ::v-deep .el-cascader {
    width: 100%;
  }
  ::v-deep .el-popper {
    margin-top: 8px !important;
  }
 
  .acc_border {
    box-shadow: 0px 0px 5px 0px rgb(9 44 55 / 28%) inset;
    border-radius: 14px;
  }
  .acc_cont_box .acc_item:last-child {
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
  }
  .acc_top {
    padding: 22px 30px;
    margin-bottom: 20px;
  }
  .button {
    padding: 0;
  }
  .progress {
    width: 860px;
    height: 10px;
    background: #f7fafc;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.14) inset;
    border-radius: 5px;
    margin: 0 20px;
  }
  .progress .progress_bar {
    position: relative;
    border-radius: 5px 0 0 5px;
  }
  .progress_bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #a11e1e;
    text-align: center;
    background-color: #337ab7;
    -webkit-box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
    -webkit-transition: width 0.3s ease;
    -o-transition: width 0.3s ease;
    transition: width 0.3s ease;
  }
  .progress .progress_bar span {
    position: absolute;
    top: -6px;
    right: -16px;
    display: block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background: #ffffff;
    box-shadow: 0px 1px 5px 0px rgba(45, 130, 130, 0.34);
  }
  .acc_top_lbl {
    font-size: 16px;
    color: #162747;
    line-height: 21px;
  }
  .acc_top_pro {
    flex:1;
    font-size: 16px;
    color: #339c9b;
    line-height: 21px;
  }
  .flex_center_start_box {
    flex: 1;
  }
  .acc_cont_box {
    width: 740px;
    /* height: 635px; */
    padding: 1px;
    overflow: hidden;
    background: #edf2f5;
    margin-bottom: 100px;
  }
  .acc_item {
    height: 70px;
    padding: 20px 30px;
    font-size: 16px;
  }
  .acc_item:nth-child(2n) {
    background: #f7fafc;
  }
  .acc_ic .item_success {
    font-size: 26px;
  }
  .acc_ic .item_fail {
    color: #92a2bc;
    font-size: 34px;
    margin-right: -4px !important;
  }
  .acc_ic i.icon-yirenzheng {
    color: #339c9b;
  }
  .pdf_img {
    width: 30px;
    height: 34px;
  }
  .pdf_img_m {
    margin-top: 13px;
  }
  .upload_demo {
    display: flex;
  }
  /* .upload_demo >>> .el-upload-list__item-thumbnail{
                                                    width: 30px;
                                                    height: 34px;
                                                    text-align: center;
                                                    margin-left: 12px;
                                                    margin-top: 13px;
                                                } */
  .upload_demo >>> .el-upload-list__item {
    margin-top: 0;
  }
  .upload_demo >>> .el-upload-list {
    margin-top: 0;
  }
  .acc_lbl {
    width: 148px;
    color: #54657f;
  }
  .acc_inpt {
    margin: 0 100px 0 30px;
    width: 380px;
  }
  .acc_inpt >>> .el-tooltip{
    display:-webkit-box;
    -webkit-line-clamp:2;
    -webkit-box-orient:vertical;
    overflow:hidden;
    text-overflow:ellipsis;
  }
  .li_img,
  .upload_box {
    width: 60px;
    height: 60px;
    margin-right: 6px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }
  .li_img img {
    width: 60px;
    height: 60px;
  }
  .upload_box i {
    font-size: 20px;
    line-height: 60px;
    color: #7c969b;
  }
  .upload_box i:focus {
    color: #7c969b;
  }
  .edit_account >>> .el-form-item__label {
    position: relative;
    width: 170px;
    text-align: left;
    padding-right: 10px;
  }
  .edit_account >>> .el-date-editor.el-input {
    width: 388px;
  }
  .edit_account >>> .el-input__prefix {
    left: 358px;
  }
  .edit_account >>> .el-select {
    width: 100%;
  }
  .err_bg {
    position: absolute;
    width: 280px;
    height: 68px;
    line-height: 58px;
    font-size: 14px;
    color: #fb6476;
    text-align: center;
    background-image: url(../../../../assets/img/log_err_bg.png);
    background-size: 280px 66px;
    background-repeat: no-repeat;
    top: -58px;
    left: 21.5%;
    z-index: 88;
  }

  .err_bg .icon-jinggao {
    font-size: 16px;
    color: #fb6476;
  }
  .m_btn_left1 {
    margin-left: 50px;
  }
  .m_btn_left2 {
    margin-left: 30px;
  }
  .el-form-item__content {
    display: flex;
  }
  .edit_account >>> .two_select {
    margin-bottom: 0;
  }
  .edit_account >>> .two_select .el-select {
    width: 167px;
  }
  .edit_account >>> .two_select:first-child .el-select {
    margin-right: 10px;
  }
  .edit_account >>> .el-upload-list {
    width: 100%;
  }
  .edit_account >>> .el-form-item__content {
    display: flex;
  }
  .revoke_confirm_box {
    font-size: 14px;
  }
  .li_img {
    position: relative;
    text-align: center;
  }
  .close_icon {
    position: absolute;
    top: -6px;
    left: 52px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fb6476;
    cursor: pointer;
  }
  .close_icon i {
    position: relative;
    top: -7px;
    left: 0;
    font-size: 8px;
    color: #ffffff;
  }
  ::v-deep .el-upload-list--picture .el-upload-list__item {
    display: none;
  }
  @media screen and (max-width: 1664px) {
    .acc_top {
      padding: 15px 20px;
      margin-bottom: 20px;
    }
    .acc_border {
      box-shadow: 0px 0px 3px 0px rgb(9 44 55 / 28%) inset;
      border-radius: 9px;
    }
    .acc_cont_box .acc_item:last-child {
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
    }
    .progress {
      width: 560px;
      height: 7px;
      box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 14%) inset;
      border-radius: 3px;
      margin: 0 13px;
    }
    .acc_top_lbl,
    .acc_top_pro {
      font-size: 12px;
      line-height: 14px;
    }
    .m_btn_left1 {
      margin-left: 33px;
    }
    .m_btn_left2 {
      margin-left: 20px;
    }
    .progress .progress_bar span {
      top: -4px;
      right: -11px;
      width: 13px;
      height: 13px;
      box-shadow: 0px 1px 3px 0px rgb(45 130 130 / 34%);
    }
    .acc_cont_box {
      width: 488px;
      /* height: 420px; */
      padding: 1px;
      margin-bottom: 65px;
    }
    .acc_item {
      height: 46px;
      padding: 13px 20px;
      font-size: 12px;
    }
    .acc_lbl {
      width: 234px;
    }
    .acc_ic .item_success {
      font-size: 17px;
    }
    .acc_ic .item_fail {
      font-size: 22px;
      margin-right: -3px !important;
    }
    .footer_button1 {
      padding: 0;
    }
    .pdf_img {
      width: 20px;
      height: 22px;
    }
    .pdf_img_m {
      margin-top: 8px;
    }
    .edit_account >>> .el-form-item__label {
      width: 164px;
      padding-right: 7px;
    }
    .li_img,
    .upload_box {
      width: 40px;
      height: 40px;
      margin-right: 4px;
      border-radius: 3px;
    }
    .li_img img {
      width: 40px;
      height: 40px;
    }
    .upload_box i {
      font-size: 13px;
      line-height: 38px;
    }
    .inner_common >>> .el-upload-list {
      width: 100%;
    }
    .edit_account >>> .two_select .el-select {
      width: 112px;
    }
    .edit_account >>> .two_select .el-select {
      width: 99px;
    }
    .edit_account >>> .two_select:first-child .el-select {
      margin-right: 6px;
    }
    .revoke_confirm_box {
      font-size: 12px;
    }
    .close_icon {
      position: absolute;
      top: -6px;
      left: 32px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #fb6476;
      transform: scale(0.8);
    }
    .close_icon i {
      position: relative;
      top: -3px;
      left: 0;
      font-size: 8px;
      color: #ffffff;
    }

    ::v-deep .el-cascader {
      line-height: 26px;
    }

    ::v-deep .el-cascader .el-input .el-icon-arrow-down {
      width: 20px;
      font-size: 12px;
      color: #92a2bc;
    }

    ::v-deep .el-cascader-node {
      padding-left: 13px !important;
      padding-right: 20px !important;
    }
    ::v-deep .popper-cascader.el-popper.el-cascader__dropdown {
      margin-top: 5px !important;
    }
  }
</style>
